var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detection-report-mangement" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "报告编号：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.msForm.reportCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "reportCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.reportCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用人单位：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "160px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.employerName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "employerName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.employerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "100px", label: "报告日期：" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "开始时间",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["msForm"],
                        "reportDateEnd"
                      )
                    },
                    model: {
                      value: _vm.msForm.reportDateStart,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "reportDateStart", $$v)
                      },
                      expression: "msForm.reportDateStart"
                    }
                  }),
                  _vm._v(" — "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "结束时间",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["msForm"],
                        "reportDateStart",
                        true
                      )
                    },
                    model: {
                      value: _vm.msForm.reportDateEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "reportDateEnd", $$v)
                      },
                      expression: "msForm.reportDateEnd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "检测类型：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.detectionTypeArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "detectionTypeArr", $$v)
                        },
                        expression: "msForm.detectionTypeArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("定期检测")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 3 } },
                        [_vm._v("监督检测")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("现状评价")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "报告类别：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.reportTypeArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "reportTypeArr", $$v)
                        },
                        expression: "msForm.reportTypeArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("简易登记")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 2 } },
                        [_vm._v("结果登记")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "70px", label: "检测状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.dataStateArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "dataStateArr", $$v)
                        },
                        expression: "msForm.dataStateArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 0 } },
                        [_vm._v("暂存")]
                      ),
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: 1 } },
                        [_vm._v("已提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("el-divider", { staticClass: "base-divider" }),
      _c(
        "div",
        { staticStyle: { padding: "12px 16px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryInquire(1)
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { icon: "el-icon-plus" },
              on: { click: _vm.openDialog }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.dataList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "bizName",
              label: "行政区划",
              width: "170",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "employerName",
              label: "单位名称",
              width: "360",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "detectionType",
              label: "检测类型",
              width: "120",
              "header-align": "center",
              align: "center",
              resizable: false,
              formatter: _vm.detectionTypeFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reportCode",
              label: "报告编号",
              width: "220",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reportDate",
              label: "报告日期",
              width: "120",
              "header-align": "center",
              align: "center",
              resizable: false,
              formatter: _vm.dateFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "reportType",
              label: "报告类别",
              width: "120",
              "header-align": "center",
              align: "center",
              resizable: false,
              formatter: _vm.reportTypeTypeFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "报告附件",
              width: "100",
              "header-align": "center",
              align: "center",
              resizable: false
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$zwxBase.verifyIsBlank(scope.row.reportAnnexExpList1)
                      ? [
                          _c("div", { staticClass: "zwx-folder-content" }, [
                            _c("i", {
                              staticClass:
                                "filled chis-icon-filled-file-folder",
                              staticStyle: { color: "#1990ff" }
                            }),
                            _c(
                              "span",
                              { staticClass: "zwx-folder-content-num" },
                              [_vm._v("0")]
                            )
                          ])
                        ]
                      : [
                          _c(
                            "el-dropdown",
                            {
                              attrs: { placement: "bottom", trigger: "click" }
                            },
                            [
                              _c("div", { staticClass: "zwx-folder-content" }, [
                                _c("i", {
                                  staticClass:
                                    "filled chis-icon-filled-file-folder",
                                  staticStyle: { color: "#1990ff" }
                                }),
                                _c(
                                  "span",
                                  { staticClass: "zwx-folder-content-num" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.reportAnnexExpList1.length
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                _vm._l(scope.row.reportAnnexExpList1, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "el-dropdown-item",
                                    {
                                      key: index,
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openFilePreview1(
                                            scope.row.reportAnnexExpList1,
                                            index
                                          )
                                        }
                                      }
                                    },
                                    [_c("div", [_vm._v(_vm._s(item.fileName))])]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "140",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.dataState == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.detail(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    scope.row.dataState != 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: {
                              type: "text",
                              icon: "el-icon-arrow-right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.getDetailDialog(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "10px 20px" } },
        [
          _c("base-pagination", {
            attrs: {
              parentPage: _vm.msForm.currentPage,
              pageSize: _vm.msForm.pageSize,
              total: _vm.msForm.total
            },
            on: { currentChange: _vm.queryInquire }
          })
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "detectionReportRef",
          staticClass: "detection-report-dialog",
          attrs: { title: _vm.title },
          on: {
            determine: _vm.confirmDialog,
            cancel: _vm.cancelDialog,
            close: _vm.closeDialog
          }
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticStyle: { margin: "60px 0px 65px 0px" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "center",
                    "padding-left": "75px"
                  },
                  attrs: { span: 12 }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reportType == 1,
                          expression: "reportType == 1"
                        }
                      ]
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/workplace-detection/img-easy-sel.svg")
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reportType == 2,
                          expression: "reportType == 2"
                        }
                      ]
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/workplace-detection/img-easy-nor.svg")
                        }
                      })
                    ]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticClass: "zwx-radio",
                      staticStyle: {
                        margin: "24px 0px 12px 0px",
                        "font-size": "16px"
                      },
                      attrs: { label: "1" },
                      model: {
                        value: _vm.reportType,
                        callback: function($$v) {
                          _vm.reportType = $$v
                        },
                        expression: "reportType"
                      }
                    },
                    [_vm._v("简易登记")]
                  ),
                  _c("div", { staticStyle: { color: "#bdbdbd" } }, [
                    _vm._v("只填报检测评价基本信息")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "center",
                    "padding-right": "75px"
                  },
                  attrs: { span: 12 }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reportType == 1,
                          expression: "reportType == 1"
                        }
                      ]
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/workplace-detection/img-complex-nor.svg")
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reportType == 2,
                          expression: "reportType == 2"
                        }
                      ]
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/workplace-detection/img-complex-sel.svg")
                        }
                      })
                    ]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticClass: "zwx-radio",
                      staticStyle: {
                        margin: "24px 0px 12px 0px",
                        "font-size": "16px"
                      },
                      attrs: { label: "2" },
                      model: {
                        value: _vm.reportType,
                        callback: function($$v) {
                          _vm.reportType = $$v
                        },
                        expression: "reportType"
                      }
                    },
                    [_vm._v("结果登记")]
                  ),
                  _c("div", { staticStyle: { color: "#bdbdbd" } }, [
                    _vm._v("填报检测危害因素及结果信息")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }