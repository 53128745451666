var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-expert-management-edit" },
    [
      _c(
        "el-form",
        {
          key: "expertManagementEditForm",
          ref: "expertManagementEditForm",
          staticClass: "zwx-form expert-edit-form",
          attrs: {
            model: _vm.expertManagementEditForm,
            rules: _vm.expertManagementEditFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticClass: "expert-photo-edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-right": "0px" },
                    attrs: { "label-width": "58px", align: "left" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        this.$zwxBase.verifyIsNotBlank(
                          _vm.expertManagementEditForm.photoPath
                        )
                          ? _c("img", {
                              staticStyle: {
                                width: "155px",
                                height: "191px",
                                "margin-left": "15px"
                              },
                              attrs: {
                                src:
                                  _vm.file +
                                  _vm.expertManagementEditForm.photoPath
                              }
                            })
                          : _c("img", {
                              staticStyle: {
                                width: "155px",
                                height: "191px",
                                "margin-left": "15px"
                              },
                              attrs: {
                                src: require("@/assets/images/ic_avatar_empty.svg")
                              }
                            })
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "file-upload",
                          {
                            ref: "fileUploadRef",
                            attrs: {
                              accept: ".png,.jpg,.JPG,.PNG,.jpeg,.JPEG",
                              size: 50 * 1024 * 1024,
                              sizeInfo: "50M"
                            },
                            on: {
                              showFileList: _vm.uploadPhoto,
                              success: _vm.uploadSuccessCallback
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "157px",
                                  height: "38px",
                                  "margin-left": "14px"
                                }
                              },
                              [_vm._v("上传照片")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    this.$zwxBase.verifyIsNotBlank(
                      _vm.expertManagementEditForm.photoPath
                    )
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "157px",
                                  height: "38px",
                                  "margin-left": "14px"
                                },
                                on: { click: _vm.deletePhoto }
                              },
                              [_vm._v("删除照片")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            ),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "expert-edit-row",
                  staticStyle: {
                    border: "1px  #42e3c3",
                    "flex-direction": "row"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "95px",
                        label: "专家姓名：",
                        prop: "expertName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px !important" },
                        attrs: { maxlength: "50" },
                        model: {
                          value: _vm.expertManagementEditForm.expertName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "expertName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "expertManagementEditForm.expertName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "110px",
                        label: "职称：",
                        prop: "professionalTittle"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "240px !important" },
                        attrs: { maxlength: "20" },
                        model: {
                          value:
                            _vm.expertManagementEditForm.professionalTittle,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "professionalTittle",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "expertManagementEditForm.professionalTittle"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "expert-edit-row",
                  staticStyle: {
                    border: "1px  #42e3c3",
                    "flex-direction": "row"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "95px",
                        label: "联系方式：",
                        prop: "contact"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "200px !important" },
                        attrs: { maxlength: "50" },
                        model: {
                          value: _vm.expertManagementEditForm.contact,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "contact",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "expertManagementEditForm.contact"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { height: "12px" },
                      attrs: {
                        "label-width": "110px",
                        label: "单位名称：",
                        prop: "unitName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "240px !important" },
                        attrs: { maxlength: "50" },
                        model: {
                          value: _vm.expertManagementEditForm.unitName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "unitName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "expertManagementEditForm.unitName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "expert-edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { height: "12px" },
                      attrs: {
                        "label-width": "95px",
                        label: "排序号：",
                        prop: "orderId"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input ms-unit-input",
                        staticStyle: { width: "200px !important" },
                        model: {
                          value: _vm.expertManagementEditForm.orderId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "orderId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "expertManagementEditForm.orderId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "expert-edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "95px",
                        label: "简介：",
                        prop: "expertIntroduction"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-textarea",
                        staticStyle: { width: "570px !important" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 10 },
                          maxlength: "2000",
                          resize: "none",
                          placeholder: "请输入",
                          clearable: ""
                        },
                        model: {
                          value:
                            _vm.expertManagementEditForm.expertIntroduction,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.expertManagementEditForm,
                              "expertIntroduction",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "expertManagementEditForm.expertIntroduction"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: { click: _vm.addOrModifyExpertInfo }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }