<template>
  <div class="ms-expert-management-edit">
    <el-form class="zwx-form expert-edit-form" key="expertManagementEditForm" ref="expertManagementEditForm" :model="expertManagementEditForm" :rules="expertManagementEditFormRules" label-position="right" @submit.native.prevent>
      <div style="display: flex;">
        <div class="expert-photo-edit-row">
          <el-form-item label-width="58px" style="margin-right: 0px;" align="left">
            <div style="display: flex;align-items: center">
              <img v-if="this.$zwxBase.verifyIsNotBlank(expertManagementEditForm.photoPath)" :src="file + expertManagementEditForm.photoPath" style="width: 155px;height: 191px;margin-left: 15px;" />
              <img v-else src="@/assets/images/ic_avatar_empty.svg" style="width: 155px;height: 191px;margin-left: 15px;" />
            </div>
            <div style="margin-top: 10px;">
              <file-upload ref="fileUploadRef" @showFileList="uploadPhoto" @success="uploadSuccessCallback" accept=".png,.jpg,.JPG,.PNG,.jpeg,.JPEG" :size="50 * 1024 * 1024" sizeInfo="50M">
                <el-button style="width: 157px;height: 38px;margin-left: 14px;">上传照片</el-button>
              </file-upload>
            </div>
            <div style="margin-top: 10px" v-if="this.$zwxBase.verifyIsNotBlank(expertManagementEditForm.photoPath)">
              <el-button style="width: 157px;height: 38px;margin-left: 14px;" @click="deletePhoto">删除照片</el-button>
            </div>
          </el-form-item>
        </div>
        <div>
          <div class="expert-edit-row" style="border: 1px  #42e3c3;flex-direction: row;">
            <el-form-item label-width="95px" label="专家姓名：" prop="expertName">
              <el-input class="zwx-input" style="width:200px !important;" v-model.trim="expertManagementEditForm.expertName" maxlength="50" />
            </el-form-item>
            <el-form-item label-width="110px" label="职称：" prop="professionalTittle">
              <el-input class="zwx-input" style="width:240px !important;" v-model.trim="expertManagementEditForm.professionalTittle" maxlength="20" />
            </el-form-item>
          </div>
          <div class="expert-edit-row" style="border: 1px  #42e3c3;flex-direction: row;">
            <el-form-item label-width="95px" label="联系方式：" prop="contact">
              <el-input class="zwx-input" style="width:200px !important;" v-model.trim="expertManagementEditForm.contact" maxlength="50" />
            </el-form-item>

            <el-form-item label-width="110px" label="单位名称：" prop="unitName" style="height:12px">
              <el-input class="zwx-input" style="width:240px !important;" v-model.trim="expertManagementEditForm.unitName" maxlength="50" />
            </el-form-item>
          </div>
          <div class="expert-edit-row">
            <el-form-item label-width="95px" label="排序号：" prop="orderId" style="height:12px">
              <el-input class="zwx-input ms-unit-input" style="width:200px !important;" v-model.trim="expertManagementEditForm.orderId" />
            </el-form-item>
          </div>
          <div class="expert-edit-row">
            <el-form-item label-width="95px" label="简介：" prop="expertIntroduction">
              <el-input style="width:570px !important" class="zwx-textarea" type="textarea" :autosize="{ minRows: 10 }" maxlength="2000" resize="none" v-model.trim="expertManagementEditForm.expertIntroduction" placeholder="请输入" clearable />
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyExpertInfo">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'ExpertManagementEdit',
  data() {
    return {
      opType: this.$route.params.type,
      api: this.$store.state.api,
      file: this.$store.state.file,
      baseUrl: this.$store.state.baseUrl,
      selfImg: this.$route.meta.selfImg,
      isUpload: false,
      expertManagementEditForm: {
        uuid: '',
        photoAnnexList: [],
        photoPath: '',
        expertName: '',
        professionalTittle: '',
        contact: '',
        unitName: '',
        orderId: '',
        orderIdBefore: '',
        expertIntroduction: '',
        folder: '/online_training/expert_management', //文件存储路径
      },
      expertManagementEditFormRules: {
        expertName: [{ required: true, message: '请输入专家姓名', trigger: ['blur'] }],
        contact: [{ validator: this.validateContact, trigger: ['blur'] }],
        orderId: [
          { pattern: /^\+?[0-9]\d*$/, message: '请输入整数' },
          { validator: this.validateOrderId, trigger: ['blur'] },
        ],
      },
      config: {},
    }
  },
  mounted() {
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
    if (this.opType === 'add') {
      this.$emit('breadcrumb', '添加', true)
      this.queryMaxOrderId()
    } else {
      this.$emit('breadcrumb', '编辑', true)
      this.expertManagementEditForm.uuid = this.$route.params.row.uuid
      this.expertManagementEditForm.photoPath = this.$route.params.row.photoPath
      this.expertManagementEditForm.expertName = this.$route.params.row.expertName
      this.expertManagementEditForm.professionalTittle = this.$route.params.row.professionalTittle
      this.expertManagementEditForm.contact = this.$zwxSm.sm4JsDecrypt(this.$route.params.row.contact)
      this.expertManagementEditForm.unitName = this.$route.params.row.unitName
      this.expertManagementEditForm.orderId = this.$route.params.row.orderId
      //保存编辑前排序号，仅在该排序号发生改变时触发校验规则
      this.expertManagementEditForm.orderIdBefore = this.$route.params.row.orderId
      this.expertManagementEditForm.expertIntroduction = this.$route.params.row.expertIntroduction
      this.expertManagementEditForm.photoAnnexList.push({
        filePath: this.$route.params.row.photoPath,
      })
    }
  },
  methods: {
    validateContact(rule, value, callback) {
      this.$validate.mbPeLdline(rule, value, callback)
    },
    validateOrderId(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && value !== this.expertManagementEditForm.orderIdBefore) {
        this.isExistOrderId(value, count => {
          if (count === 1) {
            callback(new Error('该排序号已存在'))
          }
          callback()
        })
      } else {
        callback()
      }
    },
    queryMaxOrderId() {
      this.$system.post(this.api + '/training/platform/queryMaxOrderId-1', null, true, true, data => {
        if (data.type === '00') {
          this.expertManagementEditForm.orderId = data.orderId + 1
        } else {
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    isExistOrderId(value, callback) {
      let data = {
        orderId: value,
        uuid: this.expertManagementEditForm.uuid,
      }
      this.$system.postJson(this.api + '/training/platform/isExistOrderId-1', data, true, true, data => {
        if (data.type === '00') {
          callback(data.count)
        } else {
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    uploadPhoto(showList) {
      showList.forEach(item => {
        let flag = this.expertManagementEditForm.photoAnnexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        if (this.$zwxBase.verifyIsNotBlank(this.expertManagementEditForm.photoAnnexList)) {
          this.expertManagementEditForm.photoAnnexList = []
        }
        this.expertManagementEditForm.photoAnnexList.push({
          fileName: item,
          filePath: window.URL.createObjectURL(this.$refs.fileUploadRef.uploadFiles[this.$refs.fileUploadRef.uploadFiles.length - 1].raw),
        })
      })
      //每次选择图片上传时，图片地址为本地，否则页面无法显示照片
      this.file = ''
      this.expertManagementEditForm.photoPath = this.expertManagementEditForm.photoAnnexList[0].filePath
      this.isUpload = true
    },
    deletePhoto() {
      this.$system.msgbox('', '提示', '是否确定删除照片？', '确定', '取消', () => {
        this.expertManagementEditForm.photoAnnexList = []
        this.expertManagementEditForm.photoPath = ''
      })
    },
    /**
     * 照片上传成功后回调
     */
    uploadSuccessCallback(file) {
      if (this.$zwxBase.verifyIsNotBlank(file)) {
        //回调成功后，图片地址切换为服务器地址，否则页面无法显示照片
        this.file = this.$store.state.file
        this.expertManagementEditForm.photoPath = file[file.length - 1].filePath
        this.saveExpertInfo()
      }
    },
    /**
     * 生成文件存储信息
     */
    generateFilesUid() {
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/photo',
      }
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.fileUploadRef.submit(data.annexUploadUid)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    addOrModifyExpertInfo() {
      this.$refs['expertManagementEditForm'].validate(valid => {
        if (valid) {
          if (this.isUpload === true) {
            //仅 当照片上传后需要生成文件Uid，否则直接保存
            this.generateFilesUid()
          } else {
            this.saveExpertInfo()
          }
        }
      })
    },
    saveExpertInfo() {
      let data = {
        ...this.expertManagementEditForm,
      }
      data.contact = this.$zwxSm.sm4JsEncrypt(data.contact)
      this.$system.postJson(
        this.api + '/training/platform/addOrModifyTrainingExpert-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$emit('loading', false)
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({ name: 'ExpertManagement', params: { fresh: true } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style scoped>
.expert-photo-edit-row {
  padding: 0 16px !important;
}

.expert-photo-edit-row:first-child {
  padding-top: 16px !important;
}

div > .expert-photo-edit-row:first-child {
  padding-top: 16px !important;
}

.expert-edit-row:first-child {
  padding-top: 16px !important;
}

.expert-edit-row {
  padding-top: 5px !important;
}
</style>
