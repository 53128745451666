<template>
  <div class="detection-report-mangement">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div>
        <!-- <el-form-item label="行政区划：" label-width="70px">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" :zoneCode12From="msForm.bizZone" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item> -->
        <el-form-item label-width="70px" label="报告编号：">
          <el-input class="zwx-input" v-model.trim="msForm.reportCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="用人单位：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="msForm.employerName" style="width: 160px !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label-width="100px" label="报告日期：">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.reportDateStart" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="开始时间" :picker-options="$validate.noBigDate($data['msForm'], 'reportDateEnd')" />
          —
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.reportDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="结束时间" :picker-options="$validate.noSmallDate($data['msForm'], 'reportDateStart', true)" />
        </el-form-item>
        <!--
        <el-form-item label="单位名称：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="msForm.employerName" style="width: 160px !important" placeholder="" clearable maxlength="50" />
        </el-form-item>
        -->
      
      </div>
   
      <div class="condition-row">
        <el-form-item label-width="70px" label="检测类型：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.detectionTypeArr">
            <el-checkbox class="zwx-checkbox" :label="1">定期检测</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="3">监督检测</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">现状评价</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label-width="70px" label="报告类别：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.reportTypeArr">
            <el-checkbox class="zwx-checkbox" :label="1">简易登记</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">结果登记</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="70px" label="检测状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.dataStateArr">
            <el-checkbox class="zwx-checkbox" :label="0">暂存</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="1">已提交</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

     
      </div>

     
      <!-- <div class="condition-row">
        <el-form-item label-width="70px" label="是否超标：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.resultArr">
            <el-checkbox class="zwx-checkbox" :label="0">未超标</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="1">超标</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="70px" label="是否整改：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.rectifyStatusArr">
            <el-checkbox class="zwx-checkbox" :label="-1">无需整改</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">待整改</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="1">已整改</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="100px" label="报告完成日期：">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.reportDateStart" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="开始时间" :picker-options="$validate.noBigDate($data['msForm'], 'reportDateEnd')" />
          —
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="msForm.reportDateEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="结束时间" :picker-options="$validate.noSmallDate($data['msForm'], 'reportDateStart', true)" />
        </el-form-item>
      </div> -->
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 16px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryInquire(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="openDialog">添加</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="bizName" label="行政区划" width="170" header-align="center" align="left"></el-table-column>
      <el-table-column prop="employerName" label="单位名称" width="360" header-align="center" align="left"></el-table-column>
      <!-- <el-table-column prop="employerName" label="单位名称" width="300" header-align="center" align="left"></el-table-column> -->
      <el-table-column prop="detectionType" label="检测类型" width="120" header-align="center" align="center" :resizable="false" :formatter="detectionTypeFormatter"></el-table-column>
      <el-table-column prop="reportCode" label="报告编号" width="220" header-align="center" align="center"></el-table-column>
    
      <!-- <el-table-column prop="organName" label="检测机构" width="300" header-align="center" align="left"></el-table-column> -->
      <el-table-column prop="reportDate" label="报告日期" width="120" header-align="center" align="center" :resizable="false" :formatter="dateFormatter"></el-table-column>
      <!-- <el-table-column prop="reportDate" label="报告完成时间" width="120" header-align="center" align="center" :resizable="false" :formatter="dateFormatter"></el-table-column> -->
      <el-table-column prop="reportType" label="报告类别" width="120" header-align="center" align="center" :resizable="false" :formatter="reportTypeTypeFormatter"></el-table-column>
      <el-table-column label="报告附件" width="100" header-align="center" align="center" :resizable="false">
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.reportAnnexExpList1)">
            <div class="zwx-folder-content">
              <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
              <span class="zwx-folder-content-num">0</span>
            </div>
          </template>
          <template v-else>
            <el-dropdown placement="bottom" trigger="click">
              <div class="zwx-folder-content">
                <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                <span class="zwx-folder-content-num">{{ scope.row.reportAnnexExpList1.length }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in scope.row.reportAnnexExpList1" :key="index" @click.native="openFilePreview1(scope.row.reportAnnexExpList1, index)">
                  <div>{{ item.fileName }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </template>
      </el-table-column>
      <!--
      <el-table-column label="合同附件" width="100" header-align="center" align="center" :resizable="false">
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.reportAnnexExpList2)">
            <div class="zwx-folder-content">
              <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
              <span class="zwx-folder-content-num">0</span>
            </div>
          </template>
          <template v-else>
            <el-dropdown placement="bottom" trigger="click">
              <div class="zwx-folder-content">
                <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                <span class="zwx-folder-content-num">{{ scope.row.reportAnnexExpList2.length }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in scope.row.reportAnnexExpList2" :key="index" @click.native="openFilePreview1(scope.row.reportAnnexExpList2, index)">
                  <div>{{ item.fileName }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="dataState" label="状态" width="100" header-align="center" align="center" :resizable="false" :formatter="dataStateFormatter">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-underway" v-if="scope.row.dataState == 0">暂存</div>
          <div class="zwx-circle-text zwx-circle-complete" v-if="scope.row.dataState == 1">已提交</div>
        </template>
      </el-table-column>
      -->
      <!-- <el-table-column prop="result" label="是否超标" width="100" header-align="center" align="center" :resizable="false">
        <template slot-scope="scope">
          <span :class="['zwx-circle-text', { 0: 'zwx-circle-complete zwx-circle-complete::before', 1: 'zwx-circle-finish zwx-circle-finish:before' }[scope.row.result]]">{{ { 0: '未超标', 1: '超标' }[scope.row.result] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rectifyStatus" label="整改状态" width="100" header-align="center" align="center" :resizable="false">
        <template slot-scope="scope">
          <span :class="['zwx-circle-text', { '-1': 'zwx-circle-complete zwx-circle-complete::before', 0: 'zwx-circle-underway zwx-circle-underway:before', 1: 'zwx-circle-underway zwx-circle-underway:before', 2: 'zwx-circle-complete zwx-circle-complete::before' }[scope.row.rectifyStatus]]">{{ { '-1': '无需整改', 0: '待整改', 1: '整改中', 2: '已整改' }[scope.row.rectifyStatus] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dataResource" label="数据来源" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ { 1: '本系统', 2: '质控平台' }[scope.row.dataResource] || '-' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" label="操作" min-width="140" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button v-if="scope.row.dataState == 1" class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button>
          <!-- <el-button  class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="getDetailDialog(scope.row)">编辑</el-button> -->
          <el-button v-if="scope.row.dataState != 1" class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="getDetailDialog(scope.row)">编辑</el-button>
          <!-- <el-button v-if="scope.row.dataState != 1" class="zwx-button zwx-button-text-26 zwx-button-danger" type="danger" @click="deleteByRid(scope.row.rid)"><span>删除</span></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <div style="padding: 10px 20px;">
      <base-pagination :parentPage="msForm.currentPage" :pageSize="msForm.pageSize" :total="msForm.total" @currentChange="queryInquire" />
    </div>
    <base-dialog class="detection-report-dialog" ref="detectionReportRef" :title="title" @determine="confirmDialog" @cancel="cancelDialog" @close="closeDialog">
      <el-row v-show="visible" style="margin: 60px 0px 65px 0px">
        <el-col :span="12" style="text-align:center;padding-left: 75px;">
          <div v-show="reportType == 1"><img src="@/assets/images/workplace-detection/img-easy-sel.svg" /></div>
          <div v-show="reportType == 2"><img src="@/assets/images/workplace-detection/img-easy-nor.svg" /></div>
          <el-radio class="zwx-radio" v-model="reportType" label="1" style="margin: 24px 0px 12px 0px;font-size: 16px;">简易登记</el-radio>
          <div style="color: #bdbdbd;">只填报检测评价基本信息</div>
        </el-col>
        <el-col :span="12" style="text-align:center;padding-right: 75px;">
          <div v-show="reportType == 1"><img src="@/assets/images/workplace-detection/img-complex-nor.svg" /></div>
          <div v-show="reportType == 2"><img src="@/assets/images/workplace-detection/img-complex-sel.svg" /></div>
          <el-radio class="zwx-radio" v-model="reportType" label="2" style="margin: 24px 0px 12px 0px;font-size: 16px;">结果登记</el-radio>
          <div style="color: #bdbdbd;">填报检测危害因素及结果信息</div>
        </el-col>
      </el-row>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'DetectionReportManagement',
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      employerUuid: null,
      reportType: '1',
      //关闭弹框图片闪一下的问题
      visible: true,
      msForm: {
        reportCode: '',
        organNameLike: '',
        dataStateArr: [],
        uploadAnnex2Arr: [],
        detectionTypeArr: [],
        reportTypeArr: [],
        reportName: '',
        reportDateStart: '',
        reportDateEnd: '',
        bizZone: '',
        employerName: '',
        resultArr: [],
        rectifyStatusArr: [],
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      dataList: [],

      title: '添加记录',
      addForm: {
        rid: null,
        detectionType: null,
        organName: null,
        reportDate: null,
        reportName: null,
        reportCode: null,
        annexList1: [],
        annexList2: [],
        organCreditCode: null,
        organId: null,
      },
    }
  },
  activated() {
    this.queryInquire(1)
  },
  created() {},
  mounted() {
    // 赋值当前用户所属地区
    // this.msForm.bizZone = '440000000000'
    // this.$nextTick(() => {
    //   this.$refs.bizZoneArea.initSearch(this.msForm.bizZone)
    //   this.$forceUpdate()
    // })
    // let url = this.$route.query
    // this.employerUuid = url.employerUuid
    this.queryInquire(1)
  },
  methods: {
    bizZoneChange() {
      this.msForm.bizZone = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    deleteByRid(rid) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        this.$emit('loading', true)
        let data = { rid: rid }
        this.$system.postJson(
          this.api + '/oh/workplace/detection/deleteTdDetectionReport-1',
          data,
          true,
          true,
          data => {
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryInquire(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'MsError', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
            this.$emit('loading', false)
          },
          this.error
        )
      })
    },
    /**
     * 详情
     */
    detail(row) {
      if (row.reportType == 1) {
        this.$router.push({ name: 'DetectionReportInfoDetail', params: { reportType: row.reportType, rid: row.rid, uuid: row.uuid } })
      } else if (row.reportType == 2) {
        this.$router.push({ name: 'DetectionReportInfoDetail', params: { reportType: row.reportType, rid: row.rid, uuid: row.uuid } })
      }
      // this.$router.push({ name: 'DetectionReportBasicInfoDetail', params: { reportType: row.reportType, rid: row.rid, uuid: row.uuid, employerUuid: row.employerUuid } })
    },
    /**
     * 编辑
     */
    getDetailDialog(row) {
      if (row.reportType == 1) {
        this.$router.push({ name: 'DetectionReportBasicInfoEdit', params: { reportType: row.reportType, rid: row.rid,  uuid: row.uuid} })
      } else if (row.reportType == 2) {
        this.$router.push({ name: 'DetectionReportDetectionResult', params: { reportType: row.reportType, rid: row.rid,uuid:row.uuid  } })
      }
    },
    /**
     * 弹窗提交

     */
    confirmDialog() {
      this.$refs.detectionReportRef.show(false)
      if (this.reportType == 1) {
        this.$router.push({ name: 'DetectionReportBasicInfoEdit', params: { reportType: this.reportType } })
      } else if (this.reportType == 2) {
        this.$router.push({ name: 'DetectionReportDetectionResult', params: { reportType: this.reportType } })
      }
      /*this.$refs['addForm'].validate(valid => {
        if (valid) {
          if(this.$zwxBase.verifyIsBlank(this.addForm.organCreditCode)){
            this.$system.notify('错误', '请选择搜索到的体检机构', 'error')
            return
          }
          if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1[0].filePath)) {
            this.submit()
          } else {
            //编辑为修改文件 直接提交
            this.generateFilesUid1()
          }
        }
      })*/
    },
    /**
     * 弹窗关闭
     */
    cancelDialog() {
      this.$refs.detectionReportRef.show(false)
    },
    /**
     * 弹窗打开
     */
    openDialog() {
      this.visible = true
      this.title = '添加记录'
      //报告类别初始化
      this.reportType = '1'
      this.$refs.detectionReportRef.show(true)
    },
    /**
     * 关闭弹窗前将form清空
     */
    closeDialog() {
      this.visible = false
      //; (this.addForm.rid = null), (this.addForm.detectionType = null), (this.addForm.organName = null), (this.addForm.reportDate = null), (this.addForm.reportName = null), (this.addForm.reportCode = null), (this.addForm.annexList1 = []), (this.addForm.organCreditCode = null), (this.addForm.organId = null)
      //this.reportType = '0'
    },
    queryInquire(currentPage) {
      this.msForm.currentPage = currentPage
      let data = {
        // currentPage: currentPage,
        // reportCode: this.msForm.reportCode,
        // organNameLike: this.msForm.organNameLike,
        // reportDateStart: this.msForm.reportDateStart,
        // reportDateEnd: this.msForm.reportDateEnd,
        // detectionTypeArr: this.msForm.detectionTypeArr,
        // reportTypeArr: this.msForm.reportTypeArr,
        // dataStateArr: this.msForm.dataStateArr,
        // uploadAnnex2Arr: this.msForm.uploadAnnex2Arr,
        // employerUuid: this.employerUuid,
        ...this.msForm,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/workplace/detection/getDetectionReportListOrgan-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.dataList = data.resultList
            this.msForm.total = data.resultCount
            this.msForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 检测类型 数据处理
     */
    detectionTypeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '1') {
          return '定期检测'
        } else if (cellValue == '2') {
          return '现状评价'
        } else if (cellValue == '3') {
          return '监督检测'
        } else if (cellValue == '5') {
          return '其他检测'
        }
      } else {
        return ''
      }
    },
    /**
     * 报告类别 数据处理
     */
    reportTypeTypeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '1') {
          return '简易登记'
        } else if (cellValue == '2') {
          return '结果登记'
        } else if (cellValue == '3') {
          return '特殊填报'
        }
      } else {
        return ''
      }
    },
    /**
     * 状态 数据处理
     */
    dataStateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '0') {
          return '暂存'
        } else if (cellValue == '1') {
          return '已提交'
        }
      } else {
        return ''
      }
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },

    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.detection-report-dialog .el-dialog {
  width: 680px !important;
  min-width: 680px !important;
}
</style>
<style lang="less">
.file-name-hidden {
  display: inline-block;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
